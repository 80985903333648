import * as React from 'react';
import { ReactNode } from 'react';
import './index.css';
import './theme/cubi.css';
import PixiApp from './containers/pixi-app';
import FlatWorld from './world';
import LevelManager, { LoadingTypes } from './levels';
import RenderUtils from './render_utils';
import WorkspaceManager from './workspace';
import Analytics from './analytics';
import Sprite from './sprite';
import MenuAppBar from './containers/Menu';
import NewVariableModal from './containers/NewVariableModal';
import RenameVariableModal from './containers/RenameVariableModal';
import SpriteEditor from './containers/SpriteEditor';
import SplitPane from 'react-split-pane';
import environment from './environment';
import { Logging } from './utils/logging';
import { Hidden } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
// (bug in version of ESLint bundled with our version of react-scripts)
// eslint-disable-next-line
import LoadingOverlay, { endLoading, withLoading } from './containers/LoadingOverlay';
import { OptionsObject as NotiStackOptionsObject, withSnackbar, WithSnackbarProps } from 'notistack';
import Toolbox from './toolbox';
import { Restore, RestoreCostumeAddStatus } from "./utils/restore";
import { translate } from './i18n/i18n';
import { SpriteEditorInstance } from "./containers/SpriteEditor/SpriteEditor";
import i18n, { t } from "i18next";
import { SessionStorage } from './utils/storage';
import { WithTranslation, withTranslation } from "react-i18next";
import GamemasterModal from "./containers/GamemasterModal";
import { downloadFileFromCloud } from './utils/awsCloud';
import { sa_event } from './utils/analytics';
import { fixeditorflag } from './containers/Menu';
import { cacheURLsInServiceWorker } from './serviceWorkerRegistration';
import { BASE_LEVEL_URL, LevelObject } from './containers/LevelLoadModal';
import Storage from './utils/storage';
import { defaultToolboox } from './utils/defaultToolbox';

import { SessionSave } from './utils/sessionSave';

const CUBI_SPRITE_URL = environment.CONTENT_FOLDER + `/it4kids/costumes/People/Cubi/${(new Date()).getMonth() === 11 ? 'Weihnachten Cubi.png' : 'Cubi.svg'}`;
const EMPTY_BACKGROUND_SPRITE_URL = environment.CONTENT_FOLDER + "/it4kids/backgrounds/Leer.png";
const CUBI_ICON_URL = "./favicon-16x16.png";

/**
 * Preloads level list and caches all levels for offline-use in service worker.
 */
const cacheAssetsForOfflineUse = () => {
    fetch(BASE_LEVEL_URL + 'summary.json')
        .then(response => response.text())
        .then(text => {
            const levelsConfig = new Array<LevelObject>(...JSON.parse(text));

            // Set config in session storage to prevent reload.
            SessionStorage.set('levelsConfig', levelsConfig);

            fetch(environment.CONTENT_FOLDER + '/it4kids/content.json').then(res => res.json()).then(json => {
                // Create offline fallback for all levels in webworker
                const allAssetUrls = [
                    EMPTY_BACKGROUND_SPRITE_URL,
                    CUBI_SPRITE_URL,
                    CUBI_ICON_URL,
                    ...json.backgrounds["."].map((url: string) => environment.CONTENT_FOLDER + "/" + url),
                    ...Object.keys(json.costumes).reduce((p, curr) => {
                        p.push(...json.costumes[curr]["."].slice(0, 5).map((url: string) => environment.CONTENT_FOLDER + "/" + url));
                        return p;
                    }, [] as string[]),
                    ...levelsConfig.filter(level => level.template_exists).map((level) => BASE_LEVEL_URL + "Templates/" + level.filename),
                    ...levelsConfig.filter(level => level.tutorial_exists).map((level) => BASE_LEVEL_URL + "Modules/" + level.filename),
                    ...levelsConfig.filter(level => level.tutorial_exists).map((level) => BASE_LEVEL_URL + "Developer/" + level.filename),
                ];
                cacheURLsInServiceWorker(allAssetUrls);
            });
        });

};
export const notifyUser = (message: string, options?: NotiStackOptionsObject) => AppRoot.INSTANCE.props.enqueueSnackbar(message, options);
Logging.initialize(notifyUser);
Restore.initialize();
SessionSave.initialize();
cacheAssetsForOfflineUse();

const fullHeightStyle = {height: '100%'};

interface AppRootProps extends WithSnackbarProps, WithTranslation {

}

type DisablePinchZoomProps = {
    children: ReactNode;
  };

const DisablePinchZoom = ({ children }: DisablePinchZoomProps) => {
    React.useEffect(() => {
        const disablePinchZoom = (e: any) => {
          if (e.touches.length > 1) {
            e.preventDefault()
          }
        }
        document.addEventListener('touchmove', disablePinchZoom, { passive: false })
        return () => {
          document.removeEventListener('touchmove', disablePinchZoom)
        }
      }, [])

      return <>{children}</>
}

export class AppRoot extends (React.Component)<AppRootProps> {

    static INSTANCE: AppRoot;
    state: {
        sprite: Sprite|null,
        isPlaying: boolean,
        isLoading: boolean,
        isOffline: boolean,
    } = {
        sprite: null,
        isPlaying: false,
        isLoading: true,
        isOffline: false
    };
    view: string = "normal";
    levelManager: LevelManager|null;
    workspaceManager: WorkspaceManager|null;
    world: FlatWorld;
    analytics: Analytics;
    pixiApp: React.RefObject<PixiApp>;

    constructor(props: any) {
        super(props);

        AppRoot.INSTANCE = this;

        this.levelManager = null;
        this.workspaceManager = null;
        this.pixiApp = React.createRef();
        this.world = new FlatWorld(1 / window.devicePixelRatio);
        this.analytics = new Analytics();

        (window as any).world = this.world;
        (window as any).analytics = this.analytics;
        (window as any).logging = Logging;
        (window as any).restore = Restore;
        (window as any).levelManager = this.levelManager;
        (window as any).app = this;

        (window as any).setSelectedSprite = this.setSelectedSprite;
        (window as any).getSelectedSprite = () => this.state.sprite;
    }

    setOfflineMode(isOffline: boolean) {
        if (isOffline && !this.state.isOffline) {
            Logging.warn(`Switching offline mode to ${isOffline}`);
            notifyUser(t("OfflineMode.IsOfflineNow"), {variant: "warning"});
        } else if (!isOffline && this.state.isOffline) {
            Logging.warn(`Switching offline mode to ${isOffline}`);
            notifyUser(t("OfflineMode.IsBackOnline"), {variant: "success"});
        }
        this.setState({
            isOffline: isOffline
        });
    }

    @withLoading({autoEnd: false})
    componentDidMount() {
        Logging.info(`Booting ${environment.APP_NAME}...`);

        (window as any).renderUtils = new RenderUtils(this.world, this.pixiApp.current!.getRenderer());
        this.pixiApp.current!.windowResized();

        // Create workspaceManager at location blocklyArea.
        this.workspaceManager = new WorkspaceManager('blocklyArea',
            this.world);
        (window as any).workspaceManager = this.workspaceManager;
        this.workspaceManager.onActiveWorkspaceChanged = (ws, sprite) => this.setSelectedSprite(sprite);

        // Set spamFilterList for tracking if someone send more than 3 reports in the last 5 min
        let spamFilterList = '0,0,0';
        sessionStorage.setItem("spamFilterList", spamFilterList);

        // Load level
        this.levelManager = new LevelManager(this.workspaceManager, this.world);
        this.levelManager.onEmpty = () => this.setSelectedSprite(null);
        (window as any).levelManager = this.levelManager;

        //Load level from URL or default level.
        const urlParams = new URLSearchParams(window.location.search);
        const queryLevelUrl = urlParams.get('level') || '';
        let queryLevelCode = urlParams.get('levelCode') || '';
        const teacher = urlParams.get('teacher');
        const embedded = urlParams.get('embedded');
        const debug = urlParams.get('debug');
        const isBuildMode = teacher || SessionStorage.get('SkipPassword');
        if (embedded) {
            this.view = 'embedded';
        } else if (debug) {
            this.view = 'debug';
        }
        if (teacher) {
            SessionStorage.set('SkipPassword', '1');
        }
        (window as any).app_onResizeSplitPanel = this.onResizeSplitPanel;
        (window as any).addEventListener('message', this.loadProjectFromParent);
        const importCallback = () => {
            //Despite using the level finsihed callback, we need to wait some more time.
            //This is a BUG. I assume that Blockly (or maybe even our code) is doing some background work async so that
            //no blocks are present when the callback is called.
            //Using setTimeout(..., 0) sets the this.runProject() to the current bottom of the javascript stack, so that we are queued after all other async functions.)
            setTimeout(() => {
                ((window as any).renderUtils as RenderUtils).generateColorPalette();
                this.onResizeSplitPanel();
                endLoading();
            }, 0);
        };

        // Extract correct level to load
        const levelURL = window.atob(queryLevelUrl) || 'https://levels.it-for-kids.org/Modules/Esuri_und_die_Birne.cubi';
        if (levelURL.startsWith('https://s3.eu-central-1.amazonaws.com/userlevels.i4k/')) {
            const parsedURL = new URL(levelURL);
            queryLevelCode = parsedURL.pathname.split('/').slice(-1)[0];
            window.history.pushState({}, document.title, '?lang=' + i18n.language + '&levelCode=' + queryLevelCode);
        }

        if (Storage.get("fixeditor")) {
            Restore.loadLastDownloadedLevel();
            Storage.del("fixeditor");
            return;
        }

        if (SessionSave.canLoadLocalLevelSave(queryLevelCode || levelURL)) {
            SessionSave.loadLocalLevelSave(queryLevelCode || levelURL);
        } else {
            // Load level directly by downloading user level or from url if different source
            if (queryLevelCode !== '') {
                downloadFileFromCloud(queryLevelCode).then((resp) => {
                    (window as any).levelManager.importFile(resp || '', {
                        type: LoadingTypes.CLOUD_JSON,
                        buildMode: isBuildMode,
                        levelCodeOrUrl: queryLevelCode
                    }, undefined, importCallback);
                });
            } else {
                this.levelManager.importFile(levelURL, {
                    type: LoadingTypes.URL_JSON,
                    buildMode: isBuildMode,
                    levelCodeOrUrl: levelURL
                }, undefined, importCallback);
            }
        }
    }

    /**
     * Responsible for loading a level sent via <postMessage> when inside an iframe
     */
    private loadProjectFromParent(event: MessageEvent) {
        if (window.self !== window.top) { // Check if we are in an iFrame
            if (!event.data || event.origin === (window as any).location.origin) {
                return;
            }
            (window as any).levelManager.importFile(event.data, {type: LoadingTypes.LOCAL_JSON}, undefined, () => {
                setTimeout(() => {
                    ((window as any).renderUtils as RenderUtils).generateColorPalette();
                    (window as any).app_onResizeSplitPanel();
                    endLoading();
                }, 0);
            });
        }
    }

    // runs all workspaces and adds 1 count for analytics
    runProject = () => {
        // Update react state
        this.setState({
            isPlaying: true
        });
        // Make sure we register key events again
        this.workspaceManager!.api.sensing.ignoreAllKeyInputs(false);
        // Reset visibility
        this.world.setVisibility(1);

        if (this.workspaceManager!.codeRunner.isPaused()) {
            this.workspaceManager!.codeRunner.unpause();
        } else {
            this.levelManager!.reset();
            this.workspaceManager!.runStart();
            this.analytics.addProjectExecutionsCount();
        }
    };

    pauseProject = () => {
        // Temporarly deactivate key events
        this.workspaceManager!.api.sensing.ignoreAllKeyInputs(true);
        // Reset the state of pressed keys
        this.workspaceManager!.input.reset();
        // Make sure no code is running
        this.workspaceManager!.codeRunner.pause();
        // Make a visual indicator, everything is paused
        this.world.setVisibility(0.4);
        //removed the sprite position reset because it is not really necessary for the users since sprites cant leave the boundaries
        this.setState({
            isPlaying: false
        });
        SpriteEditorInstance.INSTANCE.updateDragging();
        SpriteEditorInstance.readNewValues();
    };

    stopProject = () => {
        this.world.setVisibility(0.1);
        this.setState({
            isPlaying: false
        });
        this.workspaceManager!.codeRunner.stop();
    };

    resetProject = () => {
        this.setState({
            isPlaying: false
        });
        this.world.setVisibility(1);
        this.workspaceManager!.codeRunner.unpause();
        this.workspaceManager!.stopAll();
        this.world.resetSprites();
        this.levelManager!.reset();
        this.workspaceManager!.api.sensing.ignoreAllKeyInputs(false);
        this.workspaceManager!.input.reset();
        this.analytics.addProjectResetCount();
    };

    newSprite = () => {
        Logging.info("Adding new sprite.");
        const {t} = this.props;
        let sprite = new Sprite("Cubi");
        if (!this.world.centerAsOrigin) {
            sprite.x = FlatWorld.worldSize().width / 2;
            sprite.y = FlatWorld.worldSize().height / 2;
        }
        if (!LevelManager.isBuildMode()) {
            sprite.deletable = true;
        }
        if (Restore.costumeAddStatus !== RestoreCostumeAddStatus.RESET) {
            Restore.costumeAddStatus = RestoreCostumeAddStatus.SPRITE;
        }
        sprite.addCostumeUrl("Cubi", CUBI_SPRITE_URL);
        sprite.setStartConfiguration();
        this.workspaceManager!.new(sprite);
        this.setSelectedSprite(sprite);
        if (!LevelManager.isBuildMode()) {
            this.workspaceManager!.activeWorkspace!.setToolbox(new Toolbox(defaultToolboox));
        }
        notifyUser(t('SpriteEditor.NOTIFY_NEW_SPRITE'), {variant: 'success'});
    };

    duplicateSprite = () => {
        if (this.state.sprite && this.workspaceManager && this.workspaceManager.activeWorkspace) {
            const {t} = this.props;
            Logging.info("Duplicating currently selected sprite.");
            let copy = Sprite.deserialize(this.state.sprite.serialize());
            //Set ID to -1 so that it is updated to a free id.
            copy.id = -1;
            copy.updateCostume();
            copy.x += 20;
            copy.y -= 20;
            let workspaceState = this.workspaceManager.activeWorkspace.serialize();
            let toolbox = new Toolbox(workspaceState.toolbox);
            let duplicateWS = this.workspaceManager!.new(copy, toolbox);
            duplicateWS.init(workspaceState);
            Restore.createRestorePoint(translate('RestoreMessages.sprite.cloned') + this.state.sprite.name);
            this.setSelectedSprite(copy);
            notifyUser(t('SpriteEditor.NOTIFY_DUPLICATE_SPRITE'), {variant: 'success'});
        }
    };

    deleteSprite = (sprite: Sprite) => {
        Logging.info("Deleting sprite.");
        if (!sprite.isStaticBackground) {
            const {t} = this.props;
            this.world.removeSpriteById(sprite.id);
            this.workspaceManager!.removeWorkspaceById(sprite.id);
            Restore.createRestorePoint(translate('RestoreMessages.sprite.deleted') + sprite.name);
            (window as any).renderUtils.generateColorPalette();
            notifyUser(t('SpriteEditor.NOTIFY_DELETE_SPRITE'), {variant: 'success'});
        }
    };

    setAllStartPositions = async () => {
        const {t} = this.props;
        await this.world.sprites.forEach((s: Sprite) => {
            if (!s.isStaticBackground) {
                this.setStartConfig(s);
            }
        });
        notifyUser(t('SpriteEditor.NOTIFY_ALL_START_POSITIONS_SET'), {variant: 'success'});
    };

    setStartConfig = (sprite: Sprite) => {
        sprite.setStartConfiguration();
    };

    newLevel = () => {
        Logging.info("Starting new level.");
        (window as any).analytics.reportToSimpleAnalytics();
        this.levelManager!.empty();
        console.warn("app.tsx new level");
        console.warn(Number(fixeditorflag));
        if (Number(fixeditorflag) === 0) {
            sa_event('new_level');
        }
        let background = new Sprite("Hintergrund", -1, this.world.getCenter());
        Restore.blockedReset = true;
        Restore.costumeAddStatus = RestoreCostumeAddStatus.RESET;
        background.addCostumeUrl("Weiß", EMPTY_BACKGROUND_SPRITE_URL);
        this.workspaceManager!.new(background);
        LevelManager.levelURL = '';
        LevelManager.levelCodeOrUrl = '';
        LevelManager.levelTitle = 'Level';
        LevelManager.levelTitleUpdate = true;
        LevelManager.tutorial = {
            currentPageIndex: 0,
            pages: [],
            tutorialActive: false,
        };
        this.world.updateOrigin(false);
        this.newSprite();
        window.history.pushState({}, document.title, '?lang=' + i18n.language);
    };

    onResizeSplitPanel = () => {
        this.workspaceManager!.onResize();
        this.pixiApp.current!.windowResized();
    };

    setSelectedSprite = (sprite: Sprite|null) => {
        this.setState({sprite: sprite});
        SpriteEditorInstance.readNewValues();
    };

    render() {
        let splitSizeDefault = "34%";
        if (this.view === "embedded") {
            splitSizeDefault = "45%";
        }
        return (
            <DisablePinchZoom>
                <>
                    <LoadingOverlay/>
                    <div style={{minHeight: "600px", height: "100%", display: "flex", flexFlow: "column"}}>
                        <NewVariableModal/>
                        <RenameVariableModal/>
                        <GamemasterModal/>
                        <Hidden smUp={true}>
                            <div style={{
                                padding: "10px",
                                opacity: 1,
                                zIndex: 10000,
                                textAlign: 'center',
                                position: 'absolute',
                                right: 0,
                                left: 0,
                                top: 0,
                                bottom: 0,
                                background: "#1961ac",
                                color: "white"
                            }}>
                                Die Lehrsoftware Cubi von IT4Kids funktioniert aktuell nur auf Android-Tablets, iPads oder PC.
                            </div>
                        </Hidden>
                        <header>
                            <MenuAppBar
                                isPlaying={this.state.isPlaying}
                                runProject={this.runProject}
                                pauseProject={this.pauseProject}
                                resetProject={this.resetProject}
                                newSprite={this.newSprite}
                                duplicateSprite={this.duplicateSprite}
                                newLevel={this.newLevel}
                                setAllStartPositions={this.setAllStartPositions}
                            />
                        </header>
                        <main style={{flexGrow: 1, overflow: "auto", height: "100%"}}>
                            <SplitPane
                                split="vertical"
                                minSize={300}
                                maxSize={-10}
                                primary={"second"}
                                defaultSize={splitSizeDefault}
                                onChange={this.onResizeSplitPanel}
                                style={{position: "relative"}}
                            >
                                <div id="blocklyArea" style={fullHeightStyle}/>
                                <div id="playArea" style={{height: "100%", display: "flex", flexDirection: "column"}}>
                                    <PixiApp ref={this.pixiApp} world={this.world}/>
                                    <SpriteEditor onSpriteSelected={this.setSelectedSprite} deleteSprite={this.deleteSprite}
                                                setStartConfig={this.setStartConfig} sprite={this.state.sprite}/>
                                </div>
                            </SplitPane>
                        </main>
                    </div>
                </>
            </DisablePinchZoom>
        );
    }
}

export default withTranslation()(withWidth()(withSnackbar(AppRoot)));
